
import { Component, Vue } from '~/utility/pu-class-decorator'
import { useAppMenuStore } from '../../stores/useAppMenuStore'

@Component
export default class AppFooter extends Vue {
  appMenu = useAppMenuStore()

  toggleUserAccount() {
    this.appMenu.send('SHOW_ACCOUNT_MENU')
  }
}
