import { render, staticRenderFns } from "./app-header.vue?vue&type=template&id=d469dd9e&scoped=true"
import script from "./app-header.vue?vue&type=script&lang=ts"
export * from "./app-header.vue?vue&type=script&lang=ts"
import style0 from "./app-header.vue?vue&type=style&index=0&id=d469dd9e&prod&lang=css"
import style1 from "./app-header.vue?vue&type=style&index=1&id=d469dd9e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d469dd9e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PuHomepageLink: require('/app/apps/pu-links/link-homepage/PuHomepageLink.vue').default,PuSearchWizardLink: require('/app/apps/pu-links/link-search-wizard/PuSearchWizardLink.vue').default,AppHeaderFavourites: require('/app/apps/favourites/components/app-header/AppHeaderFavourites.vue').default})
