import { render, staticRenderFns } from "./AppFooterDesktop.vue?vue&type=template&id=202625b2&scoped=true"
import script from "./AppFooterDesktop.vue?vue&type=script&lang=ts"
export * from "./AppFooterDesktop.vue?vue&type=script&lang=ts"
import style0 from "./AppFooterDesktop.vue?vue&type=style&index=0&id=202625b2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "202625b2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppMenuSocialLinks: require('/app/components/layouts/components/app-menu/AppMenuSocialLinks.vue').default,AppMenuInternationalization: require('/app/components/layouts/components/app-menu/AppMenuInternationalization.vue').default,FooterLinksDesktop: require('/app/components/layouts/components/FooterLinksDesktop.vue').default,Version: require('/app/components/layouts/components/Version.vue').default,Copyright: require('/app/components/layouts/components/Copyright.vue').default})
