
import { AppMenuMixin } from '../../apps/app-menu/mixin'
import AuthMixin from '~/mixins/AuthMixin'
import { Component, Mixins } from '~/utility/pu-class-decorator'

@Component
export default class AppHeaderDesktop extends Mixins(AppMenuMixin, AuthMixin) {
  megamenuOpen = false
  loginOpen = false
  menuTimer: ReturnType<typeof setTimeout>

  get loginUrl() {
    const next = ['index', 'accounts-login'].includes(this.$route.name || '')
      ? undefined
      : this.$route.fullPath
    return this.$puLocalePath({ path: '/accounts/login/', query: { next } })
  }

  dropDownMenuHandler(menu: string, open: boolean) {
    clearTimeout(this.menuTimer)
    this.menuTimer = setTimeout(() => {
      this.$nextTick(() => {
        if (menu === 'megamenu') {
          this.megamenuOpen = open
          this.loginOpen = false
        }
        if (menu === 'loginmenu') {
          this.loginOpen = open
          this.megamenuOpen = false
        }
      })
    }, 150)
  }

  megamenuClicked() {
    this.megamenuOpen = !this.megamenuOpen
  }
}
