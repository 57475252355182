import { render, staticRenderFns } from "./app-header-desktop.vue?vue&type=template&id=8ab8961c&scoped=true"
import script from "./app-header-desktop.vue?vue&type=script&lang=ts"
export * from "./app-header-desktop.vue?vue&type=script&lang=ts"
import style0 from "./app-header-desktop.vue?vue&type=style&index=0&id=8ab8961c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ab8961c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PuHomepageLink: require('/app/apps/pu-links/link-homepage/PuHomepageLink.vue').default,Translate: require('/app/components/Translate.vue').default,MegaMenu: require('/app/components/layouts/components/app-menu/MegaMenu.vue').default,AppHeaderFavourites: require('/app/apps/favourites/components/app-header/AppHeaderFavourites.vue').default})
